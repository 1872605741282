import { createRegistrar } from './createWindowMessageRegister'

// @ts-ignore
const { siteFeaturesConfigs } = window.viewerModel

const shouldCreateWindowMessageRegistrar = 'tpa' in siteFeaturesConfigs

if (shouldCreateWindowMessageRegistrar) {
	createRegistrar(window)
}
