interface Window {
	addEventListener: (type: string, listener: (event: MessageEventInit) => void) => void
	removeEventListener: (type: string, listener: (event: MessageEventInit) => void) => void
	windowMessageCleanup?: () => void
	windowMessageRegister?: (handler: (evt: MessageEventInit) => void) => void
}

export function createRegistrar(window: Window) {
	const messagesBeforeHydration: Array<MessageEventInit> = []

	function queueMessages(evt: MessageEventInit) {
		messagesBeforeHydration.push({ source: evt.source, data: evt.data, origin: evt.origin })
	}
	window.addEventListener('message', queueMessages)

	window.windowMessageRegister = function(handler) {
		messagesBeforeHydration.forEach(function(msg) {
			return handler(msg)
		})
		window.addEventListener('message', handler)
	}

	window.windowMessageCleanup = function() {
		window.removeEventListener('message', queueMessages)
	}
}
